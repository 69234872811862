import MainApp from "./MainApp";
import {Route, Routes} from "react-router-dom";

const App = () => {


    return (
        <Routes>

            <Route path="/*" element={<MainApp/>}/>
        </Routes>

    )
};


export default App;