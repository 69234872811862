import {configureStore} from '@reduxjs/toolkit';

import rootReducer from '../reducers'



export default function configureAppStore(preloadedState) {
    return configureStore({
        reducer: rootReducer, // Pass your root reducer here
        preloadedState, // Optional: You can pass initial state here
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(), // Add custom middleware here if needed
        devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools only in development
    });
}
