import React from "react";
import { Provider } from 'react-redux'
import {BrowserRouter as Router, Routes,Route } from "react-router-dom";
//import "assets/vendors/style";

// import 'antd/dist/antd.css';
import "./index.css";
import configureStore from './appRedux/store';
import App from "./containers/App/index";

const store = configureStore();

const LartonyApp = () =>
    <Provider store={store}>
        <Router >
            <Routes>
                <Route path="/*" element={<App />}/>
            </Routes>
        </Router>
    </Provider>;


export default LartonyApp;
