import React from "react";

import App from "../../routes/index";
import {useLocation} from "react-router-dom";

const MainApp = () => {
    const location = useLocation();
    return(
        <App location={location}/>
    );
}

export default MainApp;