import React, {Suspense, lazy} from "react";
import CircularProgress from "../components/CircularProgress";
import {Route, Routes} from "react-router-dom";


const LartonyHome = lazy(() => import('./LartonyHome'));
const ContactLartony = lazy(() => import('./ContactLartony'));


const App = () => (
    <Suspense fallback={<CircularProgress className="flex items-center justify-center h-screen"/>}>
        <Routes>
            <Route path="/" element={<LartonyHome/>}/>
            <Route path="/contact-us" element={<ContactLartony/>}/>
        </Routes>
    </Suspense>
);

export default App;